import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/crm-online.jpg";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    zdjecie1: file(
      relativePath: { eq: "crm-kanban-questyy.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie2: file(relativePath: { eq: "szansa-sprzedazy-zdalny-crm.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    zdjecie3: file(relativePath: { eq: "synergius-kalendarz-zdalny.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    zdjecie4: file(relativePath: { eq: "raport-crm-zdalny.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
  }
`;

const Pracazdalnacrmonline = ({ data }) => {
  return (
    <Artykul
      title="Narzędzia CRM online do pracy zdalnej"
      articleImage={obrazekArtykulu}
      articleImageAlt="Narzędzia CRM online do pracy zdalnej"
      metaTitle="Narzędzia CRM online do pracy zdalnej"
      metaDescription="Narzędzia CRM online do pracy zdalnej ✅ Jakie narzędzia CRM online ułatwią zarządzanie pracą zespołu na odległość? ✅ W jaki sposób monitorować pracę przedstawicieli handlowych zdalnie?"
    >
      <br />
      <p>
        Zarządzanie zdalnym zespołem może spędzać sen z powiek niejednemu
        kierownikowi zespołu. Przeczytaj ten artykuł i sprawdź, jak łatwo możesz
        zapanować nad pracą wieloosobowej grupy na odległość z CRM online.
      </p>

      <p>
        <strong>Dowiesz się:</strong>

        <ul>
          <li>Jak radzić sobie z wyzwaniami, które generuje zdalna praca,</li>
          <li>Jak ze zdalną pracą radzimy sobie my jako firma,</li>
          <li>Jak CRM online pomaga w pracy na odległość.</li>
        </ul>
      </p>
      <div className="text-block">
        <br />
        <LiteYouTubeEmbed
          poster="maxresdefault"
          id="qE_DnhPfvCs"
          title="CRM Online: Praca zdalna naprawdę działa!"
        />
      </div>
      <p>
        <br />
        <h2>Nowe reguły pracy</h2>
        <p>
          Od początku pandemii minął już rok i zdążyliśmy się już przyzwyczaić
          do zmiany naszych przyzwyczajeń dotyczących sposobu pracy. Biuro
          zamieniliśmy na dom, open-space’y na kanapę w salonie, a pogaduszki
          przy biurowym ekspresie do kawy przekształciliśmy w calle na Skype.
          Mnóstwo firm zostało wystawionych na trudny sprawdzian umiejętności
          szybkiego dostosowania się do nowej rzeczywistości. A jak poradziła
          sobie Twoja firma? Próbowaliście już różnych sposobów, ale żadne nie
          sprawdziło się w 100%? Zobacz, jak radzimy sobie z tym tematem w
          naszej firmie. Być może spodoba Ci się to, jak działamy i wdrożysz
          podobne rozwiązania u siebie.
        </p>
        <p>
          Jako producent systemu CRM naszym podstawowym narzędziem pracy zdalnej
          jest oczywiście… system CRM!
        </p>
        <p>
          CRM to rozwiązanie kojarzone przede wszystkim (zresztą słusznie) z
          zarządzaniem relacjami z klientami. To jednak nie tylko baza
          przydatnych informacji o klientach, ale w obecnej dobie także kopalnia
          wiedzy na temat wewnętrznej sytuacji firmy. Łączy różne elementy
          przedsiębiorstwa, wspomagając codzienną, zdalną pracę.
        </p>
<section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz sprawnie organizować pracę zdalną Twojego zespołu?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i ulepszyć pracę zdalną
            </Link>
          </div>
          </div>
    </section>
        <br />
        <h2>Monitorowanie pracy i wykonywanych zadań z CRM online</h2>
        <p>
          Fakt, że nie widzisz swoich pracowników wykonujących obowiązki z
          różnych zakątków miasta, kraju, czy nawet świata, nie oznacza, że
          znikają Twoje możliwości kontroli nad działaniami zespołu. W CRM-ie
          online możesz monitorować pracę działów handlowych, marketingowych i
          projektowych.
        </p>
        <p>
          Z jakich narzędzi CRM korzystamy, żeby weryfikować ogólną aktywność
          pracowników?
        </p>
        <ul>
          <li>
            <strong>Rejestrowanie zdarzeń pracowników</strong> to nic innego jak
            raport, w którym pracownicy zaznaczają co robili w ciągu dnia i ile
            zajmowało im to czasu. Można wybrać charakter zdarzenia - spotkanie,
            kontakt z klientem itp.
          </li>
          <br />

          <li>
            <strong>Współdzielone kalendarze </strong> - miejsce, w którym
            kierownicy i inni pracownicy mogą sprawdzać aktualne plany ich
            kolegów i koleżanek. Przydatne, gdy muszą wspólnie nad czymś
            popracować.
          </li>
          <br />
          <Lightbox
            style={{
              maxWidth: 800,
              margin: "0 auto",
            }}
            images={[data.zdjecie3]}
            alts={["Współdzielony kalendarz zadań"]}
          />
          <br />

          <li>
            <strong>Wyznaczanie celów</strong> - to opcja, która pomaga
            sprawdzić poziom realizacji celu wyznaczonego pracownikowi nawet nie
            kontaktując się z nim.
          </li>
          <br />
          <li>
            <strong>Raporty</strong> - generowane automatycznie i mogące
            przedstawić w przystępny sposób wszelkie dane zapisane w systemie.
          </li>
          <Lightbox
            style={{
              maxWidth: 800,
              margin: "0 auto",
            }}
            images={[data.zdjecie4]}
            alts={["Monitorowanie aktywności - raport"]}
          />
          <br />
        </ul>
        <br />
        <p>
          <strong>Jak można monitorować pracę handlowców? </strong> To banalnie
          proste, potrafi to wiele CRM-ów online.
        </p>

        <ul>
          <li>
            Pisaliśmy wyżej o współdzielonych kalendarzach. Handlowcy
            uzupełniają w nich spotkania i rozmowy telefonicznie. Po kontakcie
            mogą dodać notatkę, jak im poszło.
          </li>
          <br />
          <li>
            <strong>Obsługa leadów i szanse sprzedaży w widoku Kanban</strong> -
            to tablica, dzięki której w łatwy sposób sprawdzisz na jakim etapie
            lejka sprzedażowego są poszczególne szanse. Znajdziesz tu
            informacje, ustalenia z klientem, czy powiązane oferty. Kompletna
            wiedza o potencjalnej transakcji.
          </li>
          <Lightbox
            style={{
              maxWidth: 800,
              margin: "0 auto",
            }}
            images={[data.zdjecie1]}
            alts={["Szanse sprzedaży - CRM do pracy zdalnej"]}
          />
          <br />
          <li>
            <strong>Kartoteka kontrahenta</strong> - miejsce, w którym
            uprawnione osoby mogą sprawdzić ustalenia z klientem, jego dane, a
            także dokumenty np. rozliczenia.
          </li>
        </ul>
        <p>
          Nasz CRM ma dodatkowe „smaczki”, które pozwalają monitorować pracę
          działów handlowych jeszcze skuteczniej.{" "}
          <strong>
            Przykładem jest aplikacja mobilna i możliwość lokalizowania
            handlowców na mapie.
          </strong>{" "}
          Nagraliśmy na ten temat osobny odcinek, jeśli chcecie zobaczycie go
          tutaj <Link to="/aplikacja-crm-do-pracy-w-terenie"> tutaj</Link>.
        </p>
        <br />
        <h2>“W naszej firmie zarządzamy też projektami” </h2>
        <p>
          Zapytacie: Jak zarządzać nimi zdalnie? Odpowiedź nie jest
          jednoznaczna. Na rynku jest wiele narzędzi do zarządzania projektami,
          które mogą sprawdzić się w Twojej firmie. Nasze rozwiązania sprawdzą
          się świetnie, jeśli szukasz kompleksowości, czyli jednego systemu,
          który obejmie wszystkie obszary firmy.
        </p>

        <p>
          Zespoły odpowiadające za projekty dostarczają efekt w postaci grupy
          zadań wykonanych w ramach danego projektu. To pole do popisu dla
          wbudowanych w nasz CRM narzędzi takich jak tablica Kanban czy widok
          Gantt. Kanban to miejsce w którym jak na dłoni widać projekty i
          zadania projektowe oraz etapy na jakich się znajdują. Jest to bardzo
          intuicyjne narzędzie, przepływ zleceń odbywa się za pomocą drag&drop.
          Pracownicy uzupełniają tu czas poświęcony na wykonanie zadań oraz
          szacują ile pozostało do zakończenia. To wszystko sprawia, że jeden
          rzut oka na Kanbana wystarczy, żeby mieć pogląd na to, jak wygląda
          bieżąca sytuacja w realizacji.
        </p>
        <br />
        <h2>Komunikacja w firmie</h2>
        <p>
          Najważniejsza sprawa w pracy zdalnej - komunikacja. Bez niej będziecie
          błądzić jak we mgle. W takiej sytuacji warto włączyć sobie światła
          przeciwmgielne. Takimi światłami może być wbudowany w CRM komunikator.
          W Questy używamy go do omawiania tematów zawodowych. Ułatwia rozmowy
          na konkretne tematy, bo pozwala wiązać wiadomości z zadaniami,
          dokumentami, czy mailami. To proste.
        </p>
        <p>
          Z pewnością wypracowaliście sobie do tej pory jakiś kanał komunikacji.
          Odpowiedz sobie, czy jest skuteczny.
        </p>
        <br />

        <h2>Słowo o bezpieczeństwie</h2>
        <p>
          O tym mówi się za mało. Dane wychodzące poza firmę mogą być narażone
          na dostęp przez niepowołane osoby. Szukając systemu CRM online do
          zdalnej pracy dokładnie zweryfikuj opcje bezpieczeństwa zapewniane
          przez producenta, bo te nie zawsze są oczywiste. My ze swojej strony
          zapewniamy międzynarodowe standardy bezpieczeństwa danych. Wdrożyliśmy
          certyfikat ISO 27001, korzystamy z najlepszych hostingów, nasze
          systemy mają bardzo rozbudowane mechanizmy zarządzania uprawnieniami
          dostępu do poszczególnych informacji.
        </p>
      </p>
      <br />
      <h2>Podsumowanie</h2>
      <p>
        Praca zdalna to już od jakiegoś czasu standard. Różne firmy radzą sobie
        z nią lepiej lub gorzej. My, dzięki rozwiązaniom, opisanych w tym
        artykule, radzimy sobie naprawdę nieźle. To tylko niektóre przykłady
        opcji, z których możesz korzystać, decydując się na nasz CRM online.
        Dostosujemy możliwości rozwiązania do procesów zachodzących w Twojej
        firmie, dlatego można powiedzieć, że Tworzymy oprogramowanie szyte na
        miarę.
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz sprawnie organizować pracę zdalną Twojego zespołu?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
             Chcę wypróbować i ulepszyć pracę zdalną
            </Link>
          </div>
          </div>
    </section>
    </Artykul>
  );
};

export default Pracazdalnacrmonline;
